
/* eslint-disable */
import { CoursePerformanceColumnsWidth, FormatDates, CSSColors } from '@/models/enums'
import { useToast } from 'primevue/usetoast'
import { ToastLife } from '@/models/components/toast/enums'
import { IJobOffer } from '@/models/jobBank/interfaces/jobOffer'
import { JobOffersAPI } from '@/api/job-offers.api'
import { computed, defineComponent, onBeforeMount, onMounted, PropType, Ref, ref, toRefs, watch } from 'vue'
import { JobBankRouteNames } from '@/router/route-names'
import { DateTime } from 'luxon'
export default defineComponent({
  props: {
    recordsPerPage: {
      type: Number,
      default: 5
    },
    firstRecord: {
      type: Number,
      default: 0
    },
    totalRecords: {
      type: Number,
      detault: 0
    },
    jobs: {
      type: Array as PropType<IJobOffer[]>,
      default: () => []
    }
  },
  emits: ['updateFilters'],
  setup:(props) => {
    const toast = useToast()


    const publishJob = async (data : IJobOffer) => {
      try { 
        if (!data.publishDate) {

          data.publishDate = new Date();
          const endDate = new Date(data.publishDate);
          endDate.setDate(endDate.getDate() + 15);
          data.endDate = endDate;
          
          const response= await JobOffersAPI.publishJob(data)
      
          toast.add({
            severity: 'success',
            summary: 'Publicar Oferta Laboral',
            detail: `Oferta ${data.name} Publicada`,
            life: ToastLife.Default
          })
          
          return
        }
      } catch (error) {
        console.error(error)
        toast.add({
          severity: 'error',
          summary: 'No Se pudo publicar',
          detail: (error instanceof Error && error.message) || '',
          life: ToastLife.Default
        })
      }
    }

    return{
        FormatDates,
        JobBankRouteNames,
        publishJob 
    }
  }

})
